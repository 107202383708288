import Button from '@components/atoms/Button/Button';
import { ButtonProps } from '@data/strapi/Common/Types';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import cn from 'classnames';
import styles from './SurveyBar.module.scss';

type SurveyBarProps = {
  label?: string;
  button?: ButtonProps;
  className?: string;
};
export default function SurveyBar({
  label,
  button,
  className,
}: SurveyBarProps) {
  return (
    <div
      className={cn(
        styles.surveyBar,
        'bg-primary-70 color-white pt-3 pb-3 hide-print',
        className
      )}
    >
      <div className="container">
        <div className="row">
          <div className="column col-12 d-flex align-items-center">
            <div className="flex-grow-1">
              <RichText value={label || ''}></RichText>
            </div>
            <div className="flex-shrink-1">
              <Button {...button}></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
